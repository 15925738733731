import React from 'react';
import { Button, FormGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Remote } from './Remote';
import axios from 'axios';
import logo from './images/parade_color.svg';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { username: "", password: "", success: "", error: "" };
    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
                [event.target.id]: event.target.value
            });
    };

    async handleSubmit(event) {
        event.preventDefault();
	let result;
	this.setState({ error: "" });
    try {
        result = await Remote.login(this.state.username, this.state.password);
        console.log('login successful');
        console.log(JSON.stringify(result));
	    localStorage.setItem('user', JSON.stringify(result));
        this.setState({ success: 'yes' });
        this.setState({ error: '' });
        this.props.updateUser(JSON.stringify(result));
        this.props.history.push('/dashboard');
      } catch (e) {
        console.log('login failed');
        console.log(JSON.stringify(e));
        this.props.updateUser('');
        axios.defaults.headers.common['Authorization'] = null;
        this.setState({ success: '' });
        this.setState({ error: 'Incorrect login' });
      }
    }

    render() {
	let alert;
	if (this.state.success.length > 0) {
	    return (<Redirect to="/dashboard" />);
	} else if (this.state.error.length > 0) {
	    alert = <Alert variant="danger"> { this.state.error } </Alert>;
	} else {
	    alert = <span />;
	}
            return (
      <div className="Login">
	  {alert}
      <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="none">
            <div> <img width="80" src={logo} alt="logo" /></div>
          </FormGroup>
          <FormGroup controlId="username">
            <FormLabel>Username: </FormLabel>
            <FormControl autoFocus type="text" value={this.state.username} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup controlId="password">
            <FormLabel>Password: </FormLabel>
            <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
          </FormGroup>
          <Button block disabled={!this.validateForm()} type="submit"> Login </Button>
        </form>
      </div>
   );
    }
}

export default Login;