import React from 'react';
import { Form, Button, FormGroup, FormControl, FormLabel, Alert, Col } from 'react-bootstrap';
import { Remote } from './Remote';

class UploadLineup extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
          success: "",
	      error: "",
          file: ""
        };
    }

    handleChange = event => {
        this.setState({file: event.target.files[0]});
    };

    async handleSubmit(event) {
        event.preventDefault();
	    this.setState({ error: "" });
        try {
	      console.log(this.state);
          const result = await Remote.upload(this.state.file);
          if (result.startsWith('Lineup data uploaded successfully')) {
            this.setState({ success: result });
            this.setState({ error: '' });
          } else {
            this.setState({ success: '' });
            this.setState({ error: result });
          }
        } catch (e) {
          console.log(JSON.stringify(e));
	      this.setState({ success: '' });
          this.setState({ error: e.message });
        }
    }

    render() {
        let alert;
        if (this.state.success.length > 0) {
            alert = <Alert variant="success"> { this.state.success } </Alert>;
        } else if (this.state.error.length > 0) {
            alert = <Alert variant="danger"> { this.state.error } </Alert>;
        } else {
            alert = <span />;
        }

        return (
      <div className="add">
	  {alert}
      <Form onSubmit={this.handleSubmit}>

          <Form.Row>
              <FormLabel as={Col}>Upload : </FormLabel>
              <FormGroup as={Col} controlId="filename">
                <FormControl required autoFocus type="file" onChange={this.handleChange} />
              </FormGroup>
          </Form.Row>
          <Form.Group>
              <Button type="submit"> Submit </Button>
          </Form.Group>

      </Form>
      </div>
   );
    }
}

export default UploadLineup;