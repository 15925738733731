import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs, DropdownButton, Dropdown, Alert } from 'react-bootstrap';
import LineupEntry from './LineupEntry';
import LineupBySection from './LineupBySection';
import AddGroup from './AddGroup';
import UploadLineup from './UploadLineup';
import Header from './Header';


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.refresh = this.refresh.bind(this);
        this.state = { lineup: [], fullLineup: [], grouptypes: [], sections: [] };
    }

    async refresh() {
        console.log('Dashboard refresh called');
        const url = process.env.REACT_APP_API_URI;
        const response = await axios.get(`${url}parade/api/lineuptest.php`);
        console.log(response.data);
        if (response.data) {
            const grouptypes = await axios.get(`${url}parade/api/grouptypes.php`);
            const sections = await axios.get(`${url}parade/api/sections.php`);
            this.setState({ lineup: response.data, fullLineup: response.data, grouptypes: grouptypes.data, sections: sections.data });
        }
    }

    async componentDidMount() {
        //        this.timer = setInterval(() => this.refresh(), 10000);
        this.refresh();
    }

    filter = (e) => {
        const matchstr = e.target.value.toLowerCase();
        const newLineup = this.state.fullLineup.filter(l => l.name.toLowerCase().match(matchstr));
        this.setState({ lineup: newLineup });
    };

    filterByGroup = (type) => {
        if (type === 'All') {
            this.setState({ lineup: this.state.fullLineup });
        } else {
            const newLineup = this.state.fullLineup.filter(l => l.groupType === type);
            this.setState({ lineup: newLineup });
        }
    };

    render() {
        if (!this.props.user) {
            return (<Redirect to='/' />);
        }
        const user = JSON.parse(this.props.user);
        console.log(user);
        const isAdmin = user.editor;
        console.log("Admin: " + isAdmin);

        let count = 0;
        return (
            <>
                <header className="App-header" >
                    <Header user={this.props.user} updateUser={this.props.updateUser} rv={() => this.refresh()} />
                </header>
                <Tabs defaultActiveKey="lineup" onSelect={() => console.log('Tab switched')}>
                    {
                        this.state.grouptypes && this.state.lineup &&
                        <Tab eventKey="lineup" title="Lineup">
                            <div className="lineup">
                                <table className="filter">
                                    <tbody>
                                        <tr>
                                            <td><input type="text" className="center-block" placeholder="Filter by name"
                                                onChange={this.filter} /></td>
                                            <td><DropdownButton title="Filter by group type" size="sm" id="dropdown-menu-align-right"
                                                onSelect={this.filterByGroup}>
                                                <Dropdown.Item key="0" eventKey="All">All</Dropdown.Item>
                                                
                                                {this.state.grouptypes.map(s => <Dropdown.Item key={s.id} eventKey={s.name}>{s.name}</Dropdown.Item>)}
                                            </DropdownButton></td>
                                            <td width="300"> &nbsp;</td>
                                            <td align="right"> Last Updated: {new Date().toLocaleTimeString("en-US")} </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="fixed_header">
                                    <tbody>
                                        <tr className="customtableheader">
                                            <td>Order</td>
                                            <td></td>
                                            <td></td>
                                            <td>Name/description</td>
                                            <td>Group Size</td>
                                            <td>Scheduled</td>
                                            <td>Actual</td>
                                            <td>Arrival Location</td>
                                            <td>Arrival Time</td>
                                        </tr>

                                        {this.state.lineup.map(l => <LineupEntry key={l.id} entry={l} isadmin={isAdmin} count={++count} refresh={this.refresh}  />)}
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                    }
                    {
                        this.state.grouptypes && this.state.lineup &&
                        <Tab eventKey="bySection" title="Scheduled Lineup by Section">
                            <LineupBySection groups={this.state.lineup} sections={this.state.sections} />
                        </Tab>
                    }
                    {isAdmin > 0 &&
                        <Tab eventKey="addGroup" title="Add a new Marching Group">
                            <AddGroup />
                        </Tab>
                    }
                    {isAdmin > 0 &&
                        <Tab eventKey="uploadSeedData" title="Upload Initial Marching Order" >
                          <UploadLineup />
                        </Tab>
                    }
                 </Tabs>
            </>);
    }
}

export default Dashboard;