import axios from 'axios';

const config = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
};
const url = process.env.REACT_APP_API_URI;

export class Remote {

    static async login(username, pwd) {
	  const response = await axios.post(`${url}parade/api/login.php`, `username=${username}&pwd=${pwd}`, config);
      return response.data;
    }

    static async addGroup(name, description, typeId, gatheringTime, gatheringLocation, scheduledStepoffTime,
          notes, size, sectionId, clusterId) {
	  const response = await axios.post(`${url}parade/api/addgroup.php`,
        `name=${name}&description=${description}&typeId=${typeId}&gatheringTime=${gatheringTime}&gatheringLocation=${gatheringLocation}&scheduledStepoffTime=${scheduledStepoffTime}&notes=${notes}&size=${size}&sectionId=${sectionId}&clusterId=${clusterId}`, config);
         return response.data;
    }

    static async updateGroup(groupId, stepoffTime) {
      const response = await axios.post(`${url}parade/api/updategroup.php`,
        `actualStepoffTime=${stepoffTime}&id=${groupId}`, config);
         return response.data;
    }

    static async upload(file) {
		const formData = new FormData();
		formData.append('file',file);
		const uploadConfig = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const response = await axios.post(`${url}parade/api/upload.php`, formData, uploadConfig);
		return response.data;
    }
}
