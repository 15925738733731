import React from 'react';
import { Form, Button, FormGroup, FormControl, FormLabel, Alert, Col } from 'react-bootstrap';
import { Remote } from './Remote';
import axios from 'axios';

const groupProperties = ['name', 'description', 'gatheringTime', 'gatheringLocation', 'scheduledStepoffTime', 'notes', 'size', 'clusterId'];
const groupSelectProperties = ['typeId', 'sectionId'];

class AddGroup extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
	    success: "",
	    error: "",
            grouptypes: [],
            sections: [],
	    stepoffHour: "11",
	    stepoffMinute: "00",
	    ampm: "AM"
      };
      this.stepoffHour = React.createRef();
      this.stepoffMinute = React.createRef();
      groupProperties.forEach(prop => Object.assign(this.state, { [prop]: ""}));
      groupSelectProperties.forEach(prop => Object.assign(this.state, { [prop]: "1"}));
    }

    async componentDidMount() {
	  const url = process.env.REACT_APP_API_URI;
	  const grouptypes = await axios.get(`${url}parade/api/grouptypes.php`);
	  const sections = await axios.get(`${url}parade/api/sections.php`);
      const fs = { id: 0, name: 'Choose section'};
	  if (grouptypes.data && sections.data) {
          sections.data.unshift(fs);
          this.setState({grouptypes: grouptypes.data, sections: sections.data});
      }
	}

    validateForm() {
        return this.state.name.length > 0 && this.state.scheduledStepoffTime.length > 0 && this.state.gatheringLocation
            && (!this.state.size || this.state.size.match(/^[0-9]+$/));
    }

    handleChange = event => {
      const state = this.state;
      Object.assign(state, { [event.target.id]: event.target.value });
      if (event.target.id === 'sectionId') {
        const section = this.state.sections.find(s => s.id === event.target.value);
        if (section) {
           const gatheringLocation = section.gatheringLocation;
           Object.assign(state, { gatheringLocation });
        } else {
           Object.assign(state, { gatheringLocation: '' });
        }
      }
	if ((event.target.id === 'stepoffHour') || (event.target.id === 'stepoffMinute')) {
	    const scheduledStepoffTime = `${this.stepoffHour.current.value}:${this.stepoffMinute.current.value}`;
	    console.log(scheduledStepoffTime);
	    Object.assign(state, { scheduledStepoffTime });
	}
	if (event.target.id === 'stepoffHour') {
		if (event.target.value > 11) {
		    Object.assign(state, { ampm: "PM" });
		} else {
		    Object.assign(state, { ampm: "AM" });
		}
        }
        this.setState(state);
    };

    async handleSubmit(event) {
        event.preventDefault();
	    this.setState({ error: "" });
        try {
	      console.log(this.state);
          const result = await Remote.addGroup(this.state.name, this.state.description, this.state.typeId, this.state.gatheringTime, this.state.gatheringLocation, this.state.scheduledStepoffTime,
          this.state.notes, this.state.size, this.state.sectionId, this.state.clusterId);
          if (result.startsWith('Added group')) {
            this.setState({ success: result });
            this.setState({ error: '' });
          } else {
            this.setState({ success: '' });
            this.setState({ error: result });
          }
        } catch (e) {
          console.log(JSON.stringify(e));
	      this.setState({ success: '' });
          this.setState({ error: e.message });
        }
    }

    render() {
        if (!this.state.grouptypes) {
            alert = <Alert variant="danger"> Group Data is not initialized </Alert>;
            return;
        }
        if (!this.state.sections) {
            alert = <Alert variant="danger"> Section data is not initialized </Alert>;
            return;
        }

	let alert;
	if (this.state.success.length > 0) {
	    alert = <Alert variant="success"> { this.state.success } </Alert>;
	} else if (this.state.error.length > 0) {
	    alert = <Alert variant="danger"> { this.state.error } </Alert>;
	} else {
	    alert = <span />;
	}
            return (
      <div className="add">
	  {alert}
      <Form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <FormLabel>Group Name: </FormLabel>
            <FormControl required autoFocus type="text" value={this.state.name} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup controlId="description">
            <FormLabel>Description: </FormLabel>
            <FormControl value={this.state.description} onChange={this.handleChange} as="textarea" rows="3" />
          </FormGroup>
          <FormGroup controlId="typeId">
            <FormLabel>GroupType: </FormLabel>
            <FormControl as="select" onChange={this.handleChange} defaultValue="1">
              { this.state.grouptypes.map(type => <option key={type.id} value={type.id}> { type.name } </option> ) }
            </FormControl>
          </FormGroup>
          <FormGroup controlId="sectionId" onChange={this.handleChange} defaultValue="1">
            <FormLabel>Section: </FormLabel>
            <FormControl as="select">
              { this.state.sections.map(section => <option key={section.id} value={section.id}> { section.name } </option> ) }
            </FormControl>
          </FormGroup>
          <FormGroup controlId="gatheringLocation">
            <FormLabel>Gathering Location: </FormLabel>
            <FormControl value={this.state.gatheringLocation} onChange={this.handleChange} type="text" />
          </FormGroup>

          <FormGroup controlId="size">
            <FormLabel>Group Size: </FormLabel>
            <FormControl type="text" value={this.state.size} onChange={this.handleChange} />
          </FormGroup>
         <Form.Label>Scheduled Step Off Time</Form.Label>
         <Form.Row>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
           <Form.Group as={Col} controlId="stepoffHour">
      <FormControl className="narrow-form-control" as="select" onChange={this.handleChange} ref={this.stepoffHour}>
	       { [...Array(5).keys()].map(h => <option key={h} size="sm" value={h+11}> { h+11  > 12 ? h - 1 : h + 11 } </option> ) }
            </FormControl>
           </Form.Group>
           <Form.Group as={Col} controlId="stepoffMinute">
            <FormControl as="select"  className="narrow-form-control" onChange={this.handleChange} ref={this.stepoffMinute}>
	       { [...Array(60).keys()].map(m => <option key={m} size="sm" value={m.toString().padStart(2, '0')}>
               { m.toString().padStart(2, '0') } </option> ) }
            </FormControl>
           </Form.Group>
           <Form.Group as={Col} className="narrow-form-control">
            { this.state.ampm }
           </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
      <Form.Group as={Col} > &nbsp; </Form.Group>
          </Form.Row>
           <Form.Group>
          <Button className="addButton" disabled={!this.validateForm()} type="submit"> Submit </Button>
           </Form.Group>
        </Form>
      </div>
   );
    }
}

export default AddGroup;