import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, Navbar, Button, Container } from 'react-bootstrap';
import logo from './images/parade_color.svg';


function Header(props) {
    const [timer, setTimer] = useState(60);
    const [refresh, setRefresh] = useState(null);
    const [refreshType, setRefreshType] = useState('manual');

    console.log('In header: User=');
    console.log(props.user);

    let status = <Link to='/login'> Log In </Link>;
    let signedin = false;
    if (props.user && props.user !== 'undefined') {
        const userObj = JSON.parse(props.user);
        status = <><Navbar.Text className="spaceright">
                  Logged in as 
                </Navbar.Text>
                <Navbar.Text className="headerhighlight">
                    {userObj.fn}
                </Navbar.Text></>;
        signedin = true;
    }

    function logout() {
        props.updateUser('');
    }

    const countdown = () => {
        if (timer - 1 < 1) {
            setTimer(60);
            props.rv();
        } else {
           setTimer(timer - 1);
           console.log(`timer: ${timer}`);
        }
    }
    
    const cancelRefresh = () => { 
        clearInterval(refresh);
        setRefreshType('Manual');
        props.rv();
    };
    
    const myRefresh = () => {
       setRefreshType(60);
       setTimer(60);
       countdown();
    }

    useEffect(() => {
      console.log('In header useEffect');
      timer > 0 && refreshType === 60 && setRefresh(() => setInterval(countdown, 1000)); 
      return () => clearInterval(refresh);
    }, [timer]);
    
    return (
        <Navbar>
          <Container>
            <Navbar.Brand href="#home"><img width="80" src={logo} alt="logo" /></Navbar.Brand>
            <Navbar.Toggle />
                <span className="spaceright"> Refresh: </span> 
                {refreshType !== 60 && <div className="headerhighlight"><Button variant="link" className="buttonlink spaceright activebutton" onClick={cancelRefresh} > Manual</Button></div>}
                {refreshType === 60 && <div className="headerhighlight"><Button variant="link" className="buttonlink spaceright" onClick={cancelRefresh}> Back to Manual Refresh </Button></div>}
                
                {refreshType !== 60 && <div className="headerhighlight"><Button variant="link" className="buttonlink" onClick={() => myRefresh()} >Every 60 seconds</Button></div>}
                {refreshType === 60 && <div className="headerhighlight"> <span className="activebutton"> This page will refresh in </span> <span className="activeButton"> {timer}</span></div>}


            <Navbar.Collapse className="justify-content-end">
                {status}
                <Navbar.Text> &nbsp; </Navbar.Text>
                {signedin && (<NavItem className='logout' onClick={logout}>Log Out</NavItem>)}
            </Navbar.Collapse>
            </Container>
        </Navbar>

    );
}

export default Header;