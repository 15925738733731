import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Remote } from './Remote';
/*
import floatImage from './images/float.svg';
import groupImage from './images/group.svg';
import specialtyImage from './images/specialty.svg';
import truckImage from './images/truck.svg';
import bandImage from './images/band.png';
import vehicleImage from './images/cars.svg';
import bannerImage from './images/banner.svg';
import trolleyImage from './images/trolley.svg';
*/
import tv from './images/tv.svg';

function GroupInfo(props) {
    console.log(props.isadmin);
    if (props.isadmin > 0) {
        return 'Edit Group Information';
    } else {
        return 'View Group Information';
    }
}

function EditModal(props) {
    return (
        <Modal {...props} size="lg"
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <GroupInfo isAdmin={props.isadmin} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h4 className="modalGroupName"> {props.entry.name} </h4>
                <table>
                    <tbody>
                        <tr><td align="right">Gathering Time:</td><td className="gtime">{props.entry.gatheringTime}</td></tr>
                        <tr><td align="right">Gathering Location:</td><td className="gtime"> {props.entry.gatheringLocation}</td></tr>
                        { props.entry.description && <tr><td align="right">Description:</td><td> {props.entry.description}</td></tr> }
                        { props.entry.notes && <tr><td align="right">Notes:</td><td>{props.entry.notes}</td></tr> }
                        { props.entry.size && <tr><td align="right">Size:</td><td>{props.entry.size}</td></tr> }
                        <tr><td align="right"> Scheduled Stepoff Time: </td><td> {props.entry.scheduledStepoffTime}</td></tr>
                        <tr><td align="right"> Actual Stepoff Time: </td><td> {props.entry.actualStepoffTime}</td></tr>
                        { props.isadmin > 0 && <tr><td align="right"> Update Stepoff Time: </td><td><input type="text" onChange={props.onChange} values={props.entry.actualStepoffTime} /></td></tr>}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                <Button onClick={props.onSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

function LineupEntry(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [stepoffTime, setStepoffTime] = React.useState(null);

    async function onSave(refresh) {
        console.log("On save clicked");
        const error = await Remote.updateGroup(props.entry.id, stepoffTime);
        if (!error) {
            await setModalShow(false);
        }
        refresh();
    }

    function onChange(event) {
        setStepoffTime(event.target.value);
        console.log(event.target.value);
    }

    function formatTime(time) {
        //const time = entry.actualStepoffTime ? entry.actualStepoffTime : entry.scheduledStepoffTime;
        if (time) {
            const hm = time.split(":");
//            console.log(hm);
            if (hm[0] === '11') {
                return `${time}am`;
            } else if (hm[0] === '12') {
                return `${time}pm`;
            } else {
                return `${hm[0] - 12}:${hm[1]}:${hm[2]}pm`;
            }
        }
    }
    
    function formatString(str,min,length) {
        return str.length < min ? str : str.substr(0, length) + '...';
    }
/*
    let image = groupImage;
    if (props.entry.groupType === 'Truck') {
        image = truckImage;
    } else if (props.entry.groupType === 'Specialty Group') {
        image = specialtyImage;
    } else if (props.entry.groupType === 'Float') {
        image = floatImage;
    } else if (props.entry.groupType === 'Band') {
        image = bandImage;
    } else if (props.entry.groupType === 'Vehicle') {
        image = vehicleImage;
    } else if (props.entry.groupType === 'Banner') {
        image = bannerImage;
    } else if (props.entry.groupType === 'Trolley') {
        image = trolleyImage;
    }
*/    
    const bgStyle = { backgroundColor: props.entry.color };
    if (props.entry.clusterId > 0) {
        Object.assign(bgStyle, { borderLeft: "5px red solid" });
    }
    const name = formatString(props.entry.name, 40, 40);
    const descr = props.entry.description.length < 50 ? props.entry.description : props.entry.description.substr(0, 20) + '...';
    let orderclass = props.entry.hasMoved === "1" ? 'outofordergroup' : 'inordergroup';
    
    return (<tr key={props.entry.id} style={bgStyle} >
         
        <td align="center"><span className={orderclass}> { props.entry.id } </span> </td>
        {/* <td align="center"> <img width="20" src={image} /> </td> */}
        <td align="center" className="letter"> {props.entry.groupType.substring(0, 1)}</td>
        <td align="center" className="letter"> {props.entry.TV === 'Yes' && <img width="20" alt="TV" src={tv}></img> }</td>
        <td><Button onClick={() => setModalShow(true)} variant="link">{name}</Button>
            <span className="section-heading">{descr}</span></td><td>{props.entry.size}</td>
        <td className="smaller">{formatTime(props.entry.scheduledStepoffTime)}</td>
        <td className="bigger">{formatTime(props.entry.actualStepoffTime)}</td>
        <td align="center" className="smaller">{formatString(props.entry.gatheringLocation,25,22)}</td>
        <td align="center" className="smaller">{props.entry.gatheringTime}</td>
        <EditModal entry={props.entry} isadmin={props.isadmin} show={modalShow} onSave={() => onSave(props.refresh)} onChange={onChange} onHide={() => setModalShow(false)} /></tr>);
}

export default LineupEntry;