import React from 'react';
import { Button, FormGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import { Remote } from './Remote';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { password: "", confirmPassword: "", success: "", error: "" };
    }

    validateForm() {
        return this.state.confirmPassword.length > 0 && this.state.password.length > 0 
	    && this.state.password === this.state.confirmPassword;
    }

    handleChange = event => {
        this.setState({
                [event.target.id]: event.target.value
            });
    }

    async handleSubmit(event) {
        event.preventDefault();

  try {
      const response = await Remote.resetPassword(this.state.password, this.props.match.params.token);
      console.log(response.message);
      this.setState({ success: response.message });
      } catch (e) {
      this.setState({ error: "Reset token invalid or expired. Please request a new password reset from the app." });
      }
    }
    render() {
	let alert;
	if (this.state.success.length > 0) {
	    alert = <Alert variant="info"> { this.state.success } </Alert>;
	} else if (this.state.error.length > 0) {
	    alert = <Alert variant="danger"> { this.state.error } </Alert>;
	} else {
	    alert = <span />;
	}
            return (
      <div className="Login">
	  {alert}
      <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="password">
            <FormLabel>Password: </FormLabel>
            <FormControl autoFocus type="password" value={this.state.password}
               onChange={this.handleChange} />
          </FormGroup>
          <FormGroup controlId="confirmPassword">
            <FormLabel>Confirm Password: </FormLabel>
            <FormControl value={this.state.confirmPassword} onChange={this.handleChange} type="password" />
          </FormGroup>
          <Button block disabled={!this.validateForm()} type="submit" >Reset Password</Button>
        </form>
      </div>
		    );
    }
}

export default Login;