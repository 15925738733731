import React from 'react';
import { Tabs, Tab, Alert } from 'react-bootstrap';
import LineupEntry from './LineupEntry';
import tv from './images/tv.svg';

function LineupBySection(props) {
    let count = 0;

    const groupBy = (xs, key) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const bySection = props.groups && groupBy(props.groups, 'section');
    const d = props.sections && props.sections.map(section => Object.assign(section, { groups: bySection[section.name] }));
    console.log(bySection);
    console.log(props.sections);
    console.log(d);
/*
    const fetchData = async () => {
        const url = process.env.REACT_APP_API_URI;
        //    const config = {
        //      headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        //    };
        try {
           
            const groups = await axios.get(`${url}parade/api/lineuptest.php`);
            const bySection = groupBy(groups.data, 'section');
            const d = sections.data.map(section => Object.assign(section, { groups: bySection[section.name] }));
            console.log('bySection:');
            console.log(bySection);
            console.log('Lineup bySection:');
            console.log(d);
            setData(d);
        } catch (e) {
            setError(e.message);
        }
    };

    useEffect(() => { fetchData(); }, []);
*/
    return (
        <div>
            <Tabs>
            
            {d && d.map(section => 
                <Tab eventKey={section.name} key={section.id} title={section.name}>
                <div className="section" key={section.id}> Section {section.name}
                {section.tv === "Yes" && <img width="20" src={tv} />}
                {section.tv === "Maybe" && <span> <img width="20" alt="TV" src={tv} /> <span className="section-heading"> (possible) </span> </span>}
                <br /> <span className="section-heading"> Arrival Time: </span> {section.gatheringTime}
                <br />  <span className="section-heading"> Gathering Location: </span> {section.gatheringLocation}
                <br />  <span className="section-heading"> Scheduled: </span> {section.stepoffTime}
                <br />  <span className="section-heading"> Actual: </span> {section.stepoffTime}
                {section.groups &&
                    <table className="lineup">
                        <tbody>
                            {section.groups.map(l => <LineupEntry key={l.id} entry={l} count={++count} />)}
                        </tbody>
                    </table>}
            </div>
            </Tab>
            )}
            </Tabs>
        </div>);

}

export default LineupBySection;